<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 打卡设置 </template>
      <template #input>
        <a-button class="all_boder_btn" @click="edit = true">新建</a-button>
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      :rowKey="(item) => item.clockInId"
      :columns="columns"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total: total,
        current: queryParams.pageNum,
        defaultPageSize: queryParams.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      >

      <template slot="index" slot-scope="item, row, index">
        {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }}
      </template>

      <template slot="photo" slot-scope="text">
        <img class="picture" :src="text" alt="" />
      </template>

      <template slot="status" slot-scope="text">
        <span
          class="state_all"
          :class="text == 1 ? 'state_color_green' : 'state_color_red'"
          >{{ text == 1 ? "启用" : "禁用" }}</span
        >
      </template>

      <template slot="operation" slot-scope="text, record">
        <span class="blueText">
          <span @click="onStop(record)">{{
            record.status == 1 ? "禁用" : "启用"
          }}</span>
          | <span @click="onEdit(record)">编辑</span> |
          <span @click="onDel(record)">删除</span>
        </span>
      </template>
    </a-table>

    <a-modal
      v-model="edit"
      width="360px"
      title="新建背景"
      :centered="true"
      :closable="false"
    >
      <div class="all_content_box">
        <div class="content-box">
          <div class="L_R_inner">
            <!-- <span class="all_left_name all_required">上传背景</span> -->
            <div class="right_Div">
              <a-upload
                list-type="picture-card"
                :showUploadList="false"
                :loading="loadingImg"
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
                :beforeUpload="beforeUpload"
                :customRequest="customRequestImg"
              >
                <img
                  v-if="form.img"
                  :src="form.img"
                  style="max-width: 180px"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="loadingImg ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                  <span class="tips">建议尺寸：750*1215px</span>
                </div>
              </a-upload>
            </div>
          </div>
          <div class="L_R_inner">
            <div class="right_Div">
              <a-radio-group name="radioGroup" v-model="form.status">
                <a-radio :value="2"> 暂不启用 </a-radio>
                <a-radio :value="1"> 提交即启用 </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="PreventLoad" @click="editOk">确认</a-button>
        <a-button @click="editCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "图片",
    align: "center",
    dataIndex: "img",
    scopedSlots: { customRender: "photo" },
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    align: "center",
    width: "180px",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns,
      tableData: [],
      total: 0,
      edit: false,
      queryParams: {
        pageNum: 1, //页码
        pageSize: 10, // 页数
      },
      form: {
        clockInId: "",
        creator: "",
        modifier: "",
        status: 1,
        img: "",
      },
      loadingImg: false,
      PreventLoad: false
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.queryParams.pageNum = e.current;
      this.queryParams.pageSize = e.pageSize;
      this.getManageList();
    },

    // 编辑
    onEdit(e) {
      let a = JSON.stringify(e);
      this.form = JSON.parse(a);
      this.edit = true;
    },
    // 新增
    editOk(e) {
      if (!this.form.img) {
        this.$message.warning("请上传背景图");
        return;
      }
      this.PreventLoad = true;
      this.form.modifier = this.$store.state.userInfo.id; // 修改着
      this.form.creator = this.form.creator
        ? this.form.creator
        : this.$store.state.userInfo.id; // 创建者
      this.$ajax({
        method: this.form.clockInId ? "put" : "post",
        url: this.form.clockInId
          ? "/hxclass-management/clock/in/update"
          : "/hxclass-management/clock/in/insert",
        params: this.form,
      }).then((res) => {
        this.loading = false;
        this.PreventLoad = false;
        if (res.code == 200 && res.success) {
          this.edit = false;
          this.getManageList();
          this.$message.success(res.message);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 控制 启用/禁用
    onStop(e) {
      this.loading = true;
      this.$ajax({
        method: "put",
        url: "/hxclass-management/clock/in/update",
        params: {
          modifier: this.$store.state.userInfo.id,
          clockInId: e.clockInId,
          status: e.status == 1 ? 2 : 1,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.getManageList();
        }
      });
    },
    // 删除
    onDel(e) {
      let _this = this;
      this.$confirm({
        title: "确定删除该数据吗?",
        okText: "确认",
        onOk() {
          _this.loading = true;
          _this
            .$ajax({
              url: "/hxclass-management/clock/in/update",
              method: "put",
              params: {
                modifier: _this.$store.state.userInfo.id,
                clockInId: e.clockInId,
                deleted: 1,
              },
            })
            .then((res) => {
              _this.loading = false;
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.getManageList();
              }
            });
        },
        onCancel() {},
      });
    },
    editCancel(e) {
      this.edit = false;
    },
    // 上传前钩子上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 文件上传
    customRequestImg(fileData) {
      this.loadingImg = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.form.img = url
        } else {
          this.$message.error("上传失败");
        }
        this.loadingImg = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 查询列表
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/clock/in/list",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    edit() {
      if (!this.edit) {
        this.form = {
          clockInId: "",
          creator: "",
          modifier: "",
          status: 1,
          img: "",
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;
  .picture {
    width: 80px;
  }
}
.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tips {
  font-size: 12px;
  color: rgb(236, 20, 20);
}
/deep/.ant-modal-header {
  padding: 16px 24px 0px;
  border-bottom: none;
}
/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 24px 24px 14px;
}
/deep/.ant-upload-select-picture-card {
  width: 188px;
  height: 122px;
}
</style>
